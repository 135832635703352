import React                                        from 'react';
import { graphql }                                  from 'gatsby';
import Img                                          from 'gatsby-image';
import { useMediaQuery, Button, Center, Flex, Box } from '@chakra-ui/react';
import { GiDiamondRing, GiShop, GiWatch }           from 'react-icons/gi';
import styled                                       from '@emotion/styled';
import { BsTools }                                  from 'react-icons/bs';

import Heading            from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer             from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper            from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import SEO                from '@interness/web-core/src/components/modules/SEO/SEO';
import { findMedia }      from '@interness/web-core/src/components/_helpers';
import Carousel           from '@interness/web-core/src/components/media/Carousel/Carousel';
import LiveAnnouncements  from '@interness/web-core/src/components/structure/LiveAnnouncements/LiveAnnouncements';
import ConditionalWrapper from '@interness/web-core/src/components/structure/ConditionalWrapper/ConditionalWrapper';
import Link               from '@interness/web-core/src/components/elements/Link/Link';
import FramedImage        from '@interness/theme-sonora/src/components/FramedImage';
import CallToAction       from '@interness/theme-sonora/src/components/CallToAction/CallToAction';
import BrandsDisplay      from '@interness/theme-sonora/src/components/Display/BrandsDisplay';
import RandomShopItems    from '@interness/ecommerce-addon/src/components/RandomShopItems/RandomShopItems';

const SDisplay = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

const SDisplayItem = styled(Link)`
    width: ${props => props.width};
    margin-bottom: 50px;
    position: relative;
    text-decoration: none;
    @media (max-width: 640px) {
        width: 100%;
    }

    > div {
        margin-bottom: 20px;
        position: relative;
        border: 5px solid rgba(0, 0, 0, 0.8);
    }

    h4 {
        background-color: rgba(0, 0, 0, 0.8);
        color: #fff;
        text-transform: uppercase;
        padding: 10px 0;
        margin: auto;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
    }

    h5 {
        font-size: 1.2rem;
        font-weight: 300;
        text-align: left;
        margin-top: 10px;
    }

    p {
        text-align: justify;
        font-size: 0.9rem;
    }

    a {
        position: absolute;
        bottom: -65px;
    }
`;

const IndexPage = ({ data }) => {
  const services = [
    {
      title: 'Gutscheine',
      image: findMedia(data.servicesImages.media, '137-services-display-coupon').childImageSharp.fluid,
      descr: 'Gutscheine von Juwelier Spieker erfüllen Herzenswünsche. Nutzen Sie unseren kostenlosen Service mit einem Betrag Ihrer Wahl.',
      linkTo: '/gutscheine'
    },
    {
      title: 'Über uns',
      image: findMedia(data.servicesImages.media, '137-services-display-service').childImageSharp.fluid,
      descr: 'Wir möchten unseren Kunden ein ganz besonderes Einkaufserlebnis bieten.',
      linkTo: '/uber-uns'
    },
    {
      title: 'Goldschmiede Service',
      image: findMedia(data.servicesImages.media, '137-services-display-schmiedekurse').childImageSharp.fluid,
      descr: 'Unsere Goldschmiedemeister stehen Ihnen mit ihrem Wissen und ihrem handwerklichen Können auf höchstem Niveau bei allen Anliegen rund um Ihren Schmuck zur Verfügung.',
      linkTo: '/atelier-service'
    },
    {
      title: 'Events & Aktuelles',
      image: findMedia(data.servicesImages.media, '137-services-display-events').childImageSharp.fluid,
      descr: 'Regelmäßig bieten wir Ihnen Neuigkeiten und Veranstaltungen rund um die Themen Schmuck und Uhren. Erfahren Sie viel Wissenswertes, und werden Sie in unseren Kursen selbst kreativ.',
      linkTo: '/events'
    }
  ];
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  return (
    <>
      <SEO title={'Startseite'}/>
      <Carousel>
        {data[isMobile ? 'headerImagesMobile' : 'headerImages'].images.map((image) => (
          <ConditionalWrapper key={image.file.localFile.name}
                              condition={image.file.metadata && image.file.metadata.link_to}
                              wrapper={(children) => <Link to={image.file.metadata.link_to}>{children}</Link>}>
            <Img fluid={image.file.localFile.childImageSharp.fluid}
                 alt={image.file.localFile.name}/>
          </ConditionalWrapper>
        ))}
      </Carousel>
      <Wrapper>
        <Spacer height={20}/>
        <LiveAnnouncements/>
        <section>
          <Heading subtitle={'Herzlich Willkommen'} icon={<GiDiamondRing/>}>Juwelier Spieker</Heading>
          <Flex flexWrap="wrap">
            <Box w={['100%', '100%', '50%', '50%']} p={[4, 4, 4, 12]} paddingRight={0}>
              <FramedImage>
                <Img fluid={data.laden.images[0].file.localFile.childImageSharp.fluid}/>
              </FramedImage>
            </Box>
            <Box w={['100%', '100%', '50%', '50%']} p={[4, 4, 4, 12]}>
              <h3>Vertrauen seit 60 Jahren</h3>
              <p>Seien Sie herzlich willkommen bei Juwelier Spieker in Hövelhof, der schönen Sennegemeinde. <br/>Seit
                mehr als 60 Jahren ist Juwelier Spieker ein Begriff für traumhaften Schmuck, klassische und top aktuelle
                Uhren und exklusive Trauringe und Eheringe.</p>
              <p>Als Goldschmiedemeister möchte ich Ihnen Lust auf kreativen Schmuck machen. In unserer Meisterwerkstatt
                entstehen wundervolle Kleinode aus Gold und Silber.
                Wir bieten Ihnen einen umfangreichen Service für die Pflege und Reparaturen Ihrer Liebelingsstücke.
                Wir freuen uns auf Ihren Besuch in unserem modernen Geschäft in der Schlossstr. 15 in Hövelhof, wo wir
                uns in entspannter Atmosphäre ausgiebig Zeit für Ihre Wünsche nehmen möchten.</p>
              <p>Wir legen größten Wert auf individuelle und persönliche Beratung. Eine Tugend und
                Selbstverständlichkeit unseres Hauses.
                Wir laden Sie herzlich ein, bei Ihrem Besuch in unserem Geschäft Ihre Lieblingsstücke auszuwählen,
                anzuprobieren und zu begutachten.
                In unserem fein ausgewählten Sortiment finden Sie sowohl erlesenen Schmuck als auch aktuelle Trends,
                moderne und klassische Uhren, traumhafte Trauringe und Eheringe sowie extravagante Accessoires.<br/>
                Wir kaufen Ihr Altgold zu fairen und tagesaktuellen Preisen an.</p>
              <p>Bis bald in Hövelhof.<br/>Ihre Familie Spieker.</p>
            </Box>
          </Flex>
        </section>
      </Wrapper>
      <Spacer/>
      <Wrapper>
        <section>
          <Heading tag={'h2'} icon={<BsTools/>}>Individueller Service</Heading>
          <SDisplay>
            {services.map(item => (
              <SDisplayItem width={'45%'} key={item.title} to={item.linkTo}>
                <div>
                  <Img fluid={item.image} alt={item.title}/>
                  <h4>{item.title}</h4>
                </div>
                <p dangerouslySetInnerHTML={{ __html: item.descr }}/>
              </SDisplayItem>
            ))}
          </SDisplay>
        </section>
        <Spacer height={20}/>
        <section>
          <Heading tag={'h2'} icon={<GiWatch/>}>Aktuelle Highlights</Heading>
          <RandomShopItems/>
          <Spacer/>
          <Center>
            <Button sx={{ textDecoration: 'none' }} colorScheme="brand" as={Link} to="/shop">Mehr Produkte
              entdecken</Button>
          </Center>
        </section>
        <Spacer height={20}/>
        <section>
          <Heading tag={'h2'} icon={<GiShop/>}>Aktuelle Kollektionen</Heading>
          <Spacer height={40}/>
          <BrandsDisplay/>
        </section>
        <Spacer height={20}/>
      </Wrapper>
      <CallToAction/>
    </>
  )
};

export const query = graphql`
    query {
        headerImages: directusMediaCollection(name: {eq: "index-header"}) {
            name
            images: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 1980, maxHeight: 660, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
        headerImagesMobile: directusMediaCollection(name: {eq: "index-header-mobile"}) {
            name
            images: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 700, maxHeight: 700, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
        indexImages: directusMediaCollection(name: {eq: "index"}) {
            name
            media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 700, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
        couponImage: directusMediaCollection(name: {eq: "coupon"}) {
            media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 700, maxHeight: 274, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
        laden: directusMediaCollection(name: {eq: "laden"}) {
            name
            images: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 800, maxHeight: 800, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
        servicesImages: directusMediaCollection(name: {eq: "services-display"}) {
            name
            media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 800, maxHeight: 800, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default IndexPage;

